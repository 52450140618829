import QueryString from 'query-string'
export const parseSection = (data) => {
  const parsedSection = data.allMarkdownRemark.edges.reduce((acc, item) => {
    acc[item.node.frontmatter.section] = item.node
    return acc
  }, {})
  return parsedSection
}

/**
 * Get request
 */
/* global XMLHttpRequest */
const TIMEOUT = 10000
export const request = ({
  url,
  method = 'GET',
  body = null,
  headers = { 'Content-Type': 'application/json' },
}) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.timeout = TIMEOUT

    Object.keys(headers).forEach(key => {
      xhr.setRequestHeader(key, headers[key])
    })

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          let response = xhr.response
          if (response) response = JSON.parse(response)
          resolve(response, xhr)
        } catch (error) {
          reject(xhr)
        }
      } else {
        reject(xhr)
      }
    }

    xhr.onerror = xhr.onabort = xhr.ontimeout = () => reject(xhr)
    xhr.send(body ? JSON.stringify(body) : null)
  })
}

/**
 * Convert to monetary format
 */
export const numberToReal = (number) => {
  const splitedNumber = number.toFixed(2).split('.')
  splitedNumber[0] = splitedNumber[0].split(/(?=(?:...)*$)/).join('.')
  return splitedNumber.join(',')
}

/** Mimics underscore groupBy */
export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/** Convert account number */
export const interAccountMask = (value, evt) => {
  value = value.replace(/-/gi, '')
  if (value.length > 3) {
    return value.replace(/(\d{1}$)/, '-$1')
  } else {
    return value
  }
}

/** Scroll to anchor */
export const scrollToAnchor = anchorName => {
  var x
  const topElement = document.getElementById(anchorName.replace('#', ''))
  const scrollDuration = 600
  const top = topElement.offsetTop
  const target = Math.round(top)
  const duration = Math.round(scrollDuration)
  var startTime = Date.now()
  var endTime = startTime + duration

  var startTop = window.pageYOffset
  var distance = target - startTop

  var smoothStep = (start, end, point) => {
    if (point <= start) return 0
    if (point >= end) {
      return 1
    }
    x = (point - start) / (end - start)
    return x * x * (3 - 2 * x)
  }

  if (!anchorName) {
    return
  }

  if (!topElement) {
    return
  }

  return new Promise((resolve, reject) => {
    var previousTop = window.pageYOffset

    var scrollFrame = () => {
      var now = Date.now()
      var point = smoothStep(startTime, endTime, now)
      var frameTop = Math.round(startTop + (distance * point))

      if (window.pageYOffset !== previousTop) {
        resolve()
        return
      }

      window.scroll(0, frameTop)

      if (now >= endTime) {
        resolve()
        return
      }

      if (window.pageYOffset === previousTop && window.pageYOffset !== frameTop) {
        resolve()
        return
      }
      previousTop = window.pageYOffset

      setTimeout(scrollFrame, 0)
    }

    setTimeout(scrollFrame, 0)
  })
}

/** Get is visible on viewport */
export const isVisible = elem => {
  const {
    top,
    bottom,
    left,
    right,
  } = elem.getBoundingClientRect()
  const width = window.innerWidth || document.documentElement.clientWidth
  const height = window.innerHeight || document.documentElement.clientHeight
  return top >= 0 && left >= 0 && right <= width && bottom <= height
}

export const reacheadElem = (elem, area) => {
  if (!area) {
    area = 700
  }

  const yValue = elem.getBoundingClientRect().y
  const topValue = elem.getBoundingClientRect().top

  return (yValue) ? (yValue < area) : (topValue < area)
}

/** Debounce for any event listener */
export const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

/** Validate CPF */
const invalidsCPF = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
]
export const checkValidCPF = strCpf => {
  const regex = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/
  let sum = 0
  let rest

  if (!strCpf) return false

  if (!strCpf.match(regex)) return false

  strCpf = strCpf.replace(/\.|-/g, '')

  if (invalidsCPF.indexOf(strCpf) > -1) return false

  //  Validates first digit
  for (let i = 0; i < 9; i++) sum += parseInt(strCpf.charAt(i)) * (10 - i)
  rest = 11 - (sum % 11)
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(strCpf.charAt(9))) return false
  sum = 0

  //  Validates second digit
  for (let i = 0; i < 10; i++) sum += parseInt(strCpf.charAt(i)) * (11 - i)
  rest = 11 - (sum % 11)

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(strCpf.charAt(10))) return false

  return true
}

/** Validate CNPJ */
export const checkValidCNPJ = cnpj => {
  if (cnpj === undefined || cnpj === '') {
    return false
  }

  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999') {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  resultado = resultado.toString()

  if (resultado !== digitos.charAt(0)) {
    return false
  }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  resultado = resultado.toString()
  if (resultado !== digitos.charAt(1)) {
    return false
  }

  return true
}

/**
 * Ga track event
 *
 */
export const sendGAEvent = (action, category, type = 'send', callback = () => {
}) => {
  if (window && window.ga) {
    window.ga(type, {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      hitCallback: callback,
    })
  }
}

/**
 * return the space in pixels between two elements based on ids
 */
export const spaceBetween = (idA, idB) => {
  if (!idA || !idB || !document.getElementById(idA) || !document.getElementById(idB)) {
    return 0
  }
  const { bottom } = document.getElementById(idA).getBoundingClientRect()
  const { top } = document.getElementById(idB).getBoundingClientRect()
  return (top) - (bottom)
}

/**
 * Remove mask (cpf, cnpj and etc) for sending it to APIs without problems
 */
export const removeMask = (value) => {
  return value && value.replace(/\D/g, '')
}

/**
 * Create a new cookie
 * @param {Name of the cookie} cname
 * @param {Value of the cookie} cvalue
 * @param {Number of days until the cookie should expire } exdays
 */

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

/**
 * Return a cookie by name
 * @param {Name of the cookie} cname
 */
export const getCookie = (cname) => {
  if (typeof document !== 'undefined') {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
}

/**
 * Returns the value of a given url parameter
 * @param {Param name} name
 * @param {Url} url
 */

export const getParameterByName = (name, url) => {
  if (url || typeof window !== 'undefined') {
    const params = QueryString.parse(url || window.location.search.substring(1))
    return params[name]
  }
}

export const formatStringAsCurrency = (currency) => {
  let aux = currency.replace(/\D/g, '')
  aux = (aux / 100).toFixed(2) + ''
  aux = aux.replace('.', ',')
  aux = aux.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
  aux = aux.replace(/(\d)(\d{3}),/g, '$1.$2,')
  return aux
}

export const convertFloatToCurrencyString = (value) => (
  parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
)

export const getSalesforceFormId = (formId) => {
  if (process.env.ENVIRONMENT === 'production') return formId.prod
  return formId.staging
}

export const renameFormFieldsKeys = (object, renamesKeys, fieldsToBeAdded = {}) => {
  const keyValues = Object.keys(object).map(key => {
    const newKey = renamesKeys[key] || key
    return { [newKey]: object[key] }
  })

  if (fieldsToBeAdded) {
    Object.keys(fieldsToBeAdded).forEach(key => {
      keyValues[key] = fieldsToBeAdded[key]
    })
  }

  return Object.assign({}, ...keyValues, ...fieldsToBeAdded)
}

export const serializeObjIntoQueryString = (data) => {
  return Object.entries(data)
    .map(([ key, value ]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
}
/**
 * Add a custom data within the tag
 */
export const addCustomDataInsideTag = (namedata, valuedata) => {
  let customData = {}
  customData = { [`data-${namedata}`]: valuedata }
  return customData
}

/**
 * Envia o evento para o dataLayer do GTM
 */
export const sendDataLayerEvent = (event) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({ event })
  }
}

export const sendDataWithoutMainObject = (event) => {
  if (window && window.dataLayer) {
    window.dataLayer.push(event)
  }
}

const getButtonEvent = () => {
  if (window) {
    const event = window.dataLayer.map((item) => {
      const el = item['gtm.element']
      if (el) {
        return el
      } else {
        return false
      }
    }).filter((item) => item.tagName === 'BUTTON').slice(-3)[0]

    return event ? event.innerText : null
  }
}

export const sendCDPEvent = async (event, params) => {
  if (window && window.SalesforceInteractions) {
    window.SalesforceInteractions.init({
      consents: [
        {
          provider: 'OneTrust',
          purpose: window.SalesforceInteractions.ConsentPurpose.Tracking,
          status: window.SalesforceInteractions.ConsentStatus.OptIn,
        },
      ],
    })
    await window.SalesforceInteractions.sendEvent({
      interaction: {
        name: event,
        eventType: event,
        ...params,
        botao: getButtonEvent(),
      },
    })
  }
}

export const formatDate = (date) => {
  return `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(0, 4)}`
}

export const sendCDPFormData = ({ formName, cpf, email }) => {
  if (window) {
    const urls = window.localStorage.getItem('paths') || ''
    const urlList = JSON.parse(urls)
    urlList.map((item) => {
      sendCDPEvent('eventsForm', {
        formName,
        cpf,
        email,
        tituloPagina: document.title,
        url: item,
      })
     },
    )
    window.localStorage.removeItem('urls')
    window.localStorage.removeItem('paths')
  }
}

// Valor total
// Usar a taxa em decimal ex: 1.6 para 0.016
export function calculateLoanTotal (taxa, valor, parcelas, carencia = 0) {
  return (taxa / (1 - (1 / ((1 + taxa) ** parcelas)))) * ((valor) * ((1 + taxa) ** (((carencia - 30)) / 30)))
}

// Valor da parcela
// Usar a taxa em decimal ex: 1.6 para 0.016
export function calculateLoanInstallments (taxa, valorParcela, parcelas, carencia = 0) {
  return (valorParcela / (taxa / (1 - (1 / ((1 + taxa) ** parcelas))))) / ((1 + taxa) ** ((carencia - 30) / 30))
}
